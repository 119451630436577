import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';


/*Next up:
-in the equation, all first order things will have Xname as a multiplied term in ALL equations for that reaction.
-any solutes will have Sabbr. in the equations instead of just the abbr.
-dropdown for particulates will have a 0 option too.
-negative sign in a stoich term -> put negative sign in equation out in front instead of on the stoich term
*/

const Button = ({ children, onClick, className }) => (
  <button
    className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const SoluteParametersSection = ({   
  formData, 
  handleInputChange,
  openInflowMenus,
  setOpenInflowMenus,
  addObject,
  deleteObject 
}) => {
  const SinusoidalInflow = ({ solute, index, handleInputChange }) => {
    const { amplitude, period, yOffset } = solute.inflow.sinusoidal;
  
    const generateSinData = () => {
      const data = [];
      for (let x = 0; x <= period * 2; x++) {
        const y = Number(amplitude) * Math.sin((2 * Math.PI * x) / Number(period)) + Number(yOffset);
        data.push({ x, y });
      }
      return data;
    };
  
    return (
      <div>
        <Input
          label="Amplitude"
          value={amplitude}
          onChange={(value) => handleInputChange("section4", "inflow.sinusoidal.amplitude", value, index)}
        />
        <Input
          label="Period"
          value={period}
          onChange={(value) => handleInputChange("section4", "inflow.sinusoidal.period", value, index)}
        />
        <Input
          label="Y-Offset"
          value={yOffset}
          onChange={(value) => handleInputChange("section4", "inflow.sinusoidal.yOffset", value, index)}
        />
        <div className="mt-4 h-64">
          <LineChart width={500} height={300} data={generateSinData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="y" stroke="#8884d8" />
          </LineChart>
        </div>
      </div>
    );
  };
  
  const PeriodicInflow = ({ solute, index, handleInputChange }) => {
    const { amplitude, period, duration, startTime } = solute.inflow.periodic;
  
    const generatePeriodicData = () => {
      const data = [];
      const totalTime = Number(period) * 3;
      const amp = Number(amplitude);
      const per = Number(period);
      const dur = Number(duration);
      const start = Number(startTime);
  
      for (let t = 0; t <= totalTime; t += per / 50) {
        let y;
        const adjustedT = (t - start) % per;
        if (adjustedT < dur) {
          y = amp;
        } else {
          y = 0;
        }
        data.push({ x: t, y: y });
      }
      return data;
    };
  
    return (
      <div>
        <Input
          label="Amplitude"
          value={amplitude}
          onChange={(value) => handleInputChange("section4", "inflow.periodic.amplitude", value, index)}
        />
        <Input
          label="Period"
          value={period}
          onChange={(value) => handleInputChange("section4", "inflow.periodic.period", value, index)}
        />
        <Input
          label="Duration"
          value={duration}
          onChange={(value) => handleInputChange("section4", "inflow.periodic.duration", value, index)}
        />
        <Input
          label="Start Time"
          value={startTime}
          onChange={(value) => handleInputChange("section4", "inflow.periodic.startTime", value, index)}
        />
        <div className="mt-4 h-64">
          <LineChart width={500} height={300} data={generatePeriodicData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" label={{ value: 'Time', position: 'bottom' }} />
            <YAxis label={{ value: 'Inflow', angle: -90, position: 'left' }} />
            <Tooltip />
            <Line type="stepAfter" dataKey="y" stroke="#82ca9d" dot={false} />
          </LineChart>
        </div>
      </div>
    );
  };

  const InflowOptions = ({ solute, index, handleInputChange, showInflow, toggleInflow }) => {
    const handleTypeChange = (e) => {
      const newType = e.target.value;
      // First ensure the basic inflow structure exists
      if (!solute.inflow) {
        handleInputChange("section4", "inflow", {
          type: newType,
          constant: { value: "" },
          sinusoidal: { amplitude: "1", period: "1", yOffset: "1" },
          periodic: { amplitude: "1", period: "2", duration: "1", startTime: "0" },
          custom: { equation: "" }
        }, index);
      } else {
        handleInputChange("section4", "inflow.type", newType, index);
      }
    };
    
    return (
      <div className="mt-4">
        <Button 
          onClick={toggleInflow}
        >
          {showInflow ? "Hide Solute Inflow" : "Define Solute Inflow"}
        </Button>
        
        {showInflow && (
          <div className="mt-2 p-4 border rounded">
            <div className="flex space-x-4 mb-4">
              {["constant", "sinusoidal", "periodic", "custom"].map((type) => (
                <label key={type} className="flex items-center">
                  <input
                    type="radio"
                    value={type}
                    checked={solute.inflow?.type === type}
                    onChange={handleTypeChange}
                    className="mr-2"
                  />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </label>
              ))}
            </div>
            
            {solute.inflow?.type === "constant" && (
              <Input
                label="Inflow"
                value={solute.inflow?.constant?.value || ""}
                onChange={(value) => handleInputChange("section4", "inflow.constant.value", value, index)}
              />
            )}
            {solute.inflow?.type === "sinusoidal" && (
              <SinusoidalInflow 
                solute={solute} 
                index={index} 
                handleInputChange={handleInputChange}
              />
            )}
            {solute.inflow?.type === "periodic" && (
              <PeriodicInflow 
                solute={solute} 
                index={index} 
                handleInputChange={handleInputChange}
              />
            )}
            {solute.inflow?.type === "custom" && (
              <Input
                label="Custom Inflow Equation"
                value={solute.inflow?.custom?.equation || ""}
                onChange={(value) => handleInputChange("section4", "inflow.custom.equation", value, index)}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {formData.section4.map((solute, index) => (
        <div key={index} className="p-4 border rounded mb-4">
          <div className="flex">
            <div className="w-1/2 pr-4">
              <div className="flex gap-4 mb-4">
                <div className="flex-1">
                  <Input
                    label="Solute Name"
                    value={solute.name}
                    onChange={(e) => handleInputChange("section4", "name", e, index)}
                  />
                </div>
                <div className="w-32">
                  <Input
                    label="Abbreviation"
                    value={solute.abbreviation}
                    onChange={(e) => handleInputChange("section4", "abbreviation", e, index)}
                  />
                </div>
              </div>
              <Input
                label="Tank Initial Concentration (g/m³)"
                value={solute.tankInitialConc}
                onChange={(e) => handleInputChange("section4", "tankInitialConc", e, index)}
              />
              <Input
                label="Biofilm Initial Concentration (g/m³)"
                value={solute.biofilmInitialConc}
                onChange={(e) => handleInputChange("section4", "biofilmInitialConc", e, index)}
              />
              <Input
                label="Substrate Diffusion (m²/d)"
                value={solute.substrateDiffusion}
                onChange={(e) => handleInputChange("section4", "substrateDiffusion", e, index)}
              />
              <Input
                label="Effective Diffusion Coefficient (m²/d)"
                value={solute.effectiveDiffusion}
                onChange={(e) => handleInputChange("section4", "effectiveDiffusion", e, index)}
              />
              <Button
                onClick={() => deleteObject(index)}
                className="bg-red-500 hover:bg-red-600"
              >
                Delete Solute
              </Button>
            </div>
            <div className="w-1/2 pl-4">
              <InflowOptions 
                solute={solute} 
                index={index} 
                handleInputChange={handleInputChange}
                showInflow={openInflowMenus.has(index)}
                toggleInflow={() => {
                  setOpenInflowMenus(prev => {
                    const next = new Set(prev);
                    if (next.has(index)) {
                      next.delete(index);
                    } else {
                      next.add(index);
                    }
                    return next;
                  });
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <Button onClick={addObject}>
        Add New Solute
      </Button>
    </div>
  );
};

const Select = memo(({ value, onChange, options }) => (
  <select
    className="w-full px-3 py-2 border rounded"
    value={value}
    onChange={e => onChange(e.target.value)}
  >
    {options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
));

Select.displayName = 'Select';

const Input = React.memo(({ value, onChange, placeholder, label, className }) => {
  const inputRef = useRef();
  
  return (
    <div className={`mb-4 ${className || ''}`}>
      {label && <label className="block mb-1 font-semibold">{label}</label>}
      <input
        ref={inputRef}
        className="w-full px-3 py-2 border rounded"
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
});

Input.displayName = 'Input';

// Reaction components
const ReactionRow = memo(({ 
  name, 
  type,
  stoichiometry, 
  dependency, 
  parameter,
  onStoichiometryChange,
  onDependencyChange,
  onParameterChange,
  isFirstInstance,
  reaction,
  formData
}) => {
  const [isCustomMode, setIsCustomMode] = React.useState(
    stoichiometry !== '1' && stoichiometry !== '-1' && stoichiometry !== '0'
  );

  const dependencyOptions = type === 'particulate' ? [
    { value: 'none', label: 'None' },
    { value: 'first', label: 'First' }
  ] : [
    { value: 'none', label: 'None' },
    { value: 'first', label: 'First' },
    { value: 'monod', label: 'Monod' },
    { value: 'inhibition', label: 'Inhibition' }
  ];

  const stoichiometryOptions = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '-1', label: '-1' },
    { value: 'custom', label: 'Custom' }
  ];

  const handleStoichiometryChange = (value) => {
    if (value === 'custom') {
      setIsCustomMode(true);
      onStoichiometryChange(stoichiometry || '');
    } else {
      setIsCustomMode(false);
      onStoichiometryChange(value);
    }
  };

  const renderStoichiometryCell = () => {
    if (type === 'particulate') {
      const currentValue = isCustomMode ? 'custom' : (stoichiometry || '1');
      
      return (
        <div className="flex flex-col space-y-1">
          <Select
            value={currentValue}
            onChange={handleStoichiometryChange}
            options={stoichiometryOptions}
          />
          {isCustomMode && (
            <Input
              value={stoichiometry}
              onChange={onStoichiometryChange}
              className="mb-0"
            />
          )}
        </div>
      );
    } else {
      return (
        <Input
          value={stoichiometry}
          onChange={onStoichiometryChange}
        />
      );
    }
  };
  

  const renderParameterCell = () => {
    switch (dependency) {
      case 'none':
        return <div className="border-t border-gray-300 my-2"></div>;
      
      case 'first':
        if (!isFirstInstance) {
          return <div className="text-center">1</div>;
        }
        return (
          <Input
            value={parameter}
            onChange={onParameterChange}
          />
        );
      
      case 'monod':
        return (
          <div className="relative">
            <Input
              value={parameter}
              onChange={onParameterChange}
            />
            <span className="absolute right-2 top-2 text-sm text-gray-500">
              kM
            </span>
          </div>
        );
      
      case 'inhibition':
        return (
          <div className="relative">
            <Input
              value={parameter}
              onChange={onParameterChange}
            />
            <span className="absolute right-2 top-2 text-sm text-gray-500">
              ki
            </span>
          </div>
        );
      
      default:
        return null;
    }
  };

  const generateEquation = () => {
    // Check if stoichiometry is 0 first
    const stoichCoeff = reaction.stoichiometry?.[name] || '1';
    if (stoichCoeff === '0' || parseFloat(stoichCoeff) === 0) {
      if (type === 'particulate') {
        return `\\frac{dx_{${formData.section3.find(p => p.name === name)?.abbreviation || name}}}{dt} = 0`;
      } else {
        return `\\frac{ds_{${formData.section4.find(s => s.name === name)?.abbreviation || name}}}{dt} = 0`;
      }
    }
  
    // Find all 'first' type dependencies
    const firstDeps = Object.entries(reaction.dependencies || {})
      .filter(([_, dep]) => dep.type === 'first');
    const firstParam = Object.entries(reaction.dependencies || {})
      .find(([_, dep]) => dep.type === 'first')?.[1]?.parameter || '';
  
    // Base equation parts
    let equation = '';
    const isNegative = parseFloat(stoichCoeff) < 0;
    const absStorichCoeff = Math.abs(parseFloat(stoichCoeff));
  
    if (type === 'particulate') {
      const displayName = formData.section3.find(p => p.name === name)?.abbreviation || name;
      equation = `\\frac{dx_{${displayName}}}{dt} = ${isNegative ? '-' : ''}`;
    } else {
      const displayName = formData.section4.find(s => s.name === name)?.abbreviation || name;
      equation = `\\frac{ds_{${displayName}}}{dt} = ${isNegative ? '-' : ''}\\frac{1}{${absStorichCoeff}} [\\;`;
    }
  
    // Add first order parameter if it exists
    if (firstParam) {
      equation += firstParam;
    }
  
    // Add X or S terms for ALL components with 'first' dependency
    firstDeps.forEach((firstDep, index) => {
      const [firstDepName, _] = firstDep;
      const component = [...formData.section3, ...formData.section4].find(c => c.name === firstDepName);
      if (component) {
        const prefix = formData.section3.some(p => p.name === firstDepName) ? 'X' : 'S';
        if (index === 0 && firstParam) equation += ' \\cdot ';
        equation += prefix + '_{' + component.abbreviation + '}';
        if (index < firstDeps.length - 1) equation += ' \\cdot ';
      }
    });
  
    // Get all dependencies that aren't 'none' or 'first'
    const otherDependencies = Object.entries(reaction.dependencies || {})
      .filter(([_, dep]) => dep.type !== 'none' && dep.type !== 'first');
  
    // Add multiplication symbol if we have other dependencies
    if ((firstParam || firstDeps.length > 0) && otherDependencies.length > 0) {
      equation += ' \\cdot ';
    }
  
    // Add each solute dependency based on its type
    const soluteDependencies = otherDependencies
      .map(([soluteName, dep]) => {
        const solute = formData.section4.find(s => s.name === soluteName);
        const displayName = solute?.abbreviation || soluteName;
        switch (dep.type) {
          case 'monod':
            return `\\frac{S_{${displayName}}}{${dep.parameter}+S_{${displayName}}}`;
          case 'inhibition':
            return `\\frac{1}{1+\\frac{S_{${displayName}}}{${dep.parameter}}}`;
          default:
            return '';
        }
      })
      .filter(eq => eq !== '');
  
    equation += soluteDependencies.join(' \\cdot ');
  
    if (type === 'solute') {
      equation += '\\;]';
    }
  
    return equation;
  };

  return (
    <tr>
      <td className="border p-2 w-1/6">{name}</td>
      <td className="border p-2 w-24">
        {renderStoichiometryCell()}
      </td>
      <td className="border p-2 w-40">
        <Select
          value={dependency}
          onChange={onDependencyChange}
          options={dependencyOptions}
        />
      </td>
      <td className="border p-2 w-24">
        {renderParameterCell()}
      </td>
      <td className="border p-2">
        <div className="flex justify-center items-center min-h-[50px] px-4">
          <div className="whitespace-nowrap">
            <TeX math={generateEquation()} />
          </div>
        </div>
      </td>
    </tr>
  );
});

ReactionRow.displayName = 'ReactionRow';

const ReactionCard = memo(({ 
  reaction, 
  particulates, 
  solutes, 
  onUpdate, 
  onDelete,
  formData 
}) => {
  const handleFieldUpdate = useCallback((field, value) => {
    onUpdate({ ...reaction, [field]: value });
  }, [reaction, onUpdate]);

  const handleRowUpdate = useCallback((rowName, field, value) => {
    const newReaction = { ...reaction };
    if (!newReaction[field]) newReaction[field] = {};
    
    if (field === 'dependencies') {
      newReaction.dependencies = {
        ...newReaction.dependencies,
        [rowName]: {
          ...newReaction.dependencies?.[rowName],
          ...(typeof value === 'object' ? value : { type: value })
        }
      };
    } else if (field === 'stoichiometry') {
      newReaction.stoichiometry = {
        ...newReaction.stoichiometry,
        [rowName]: value
      };
    } else {
      newReaction[field] = {
        ...newReaction[field],
        [rowName]: value
      };
    }
    
    onUpdate(newReaction);
  }, [reaction, onUpdate]);

  return (
    <div className="p-4 mb-4 border rounded-lg shadow-sm bg-white">
      <div className="flex justify-between mb-4">
        <Input
          value={reaction.name}
          onChange={v => handleFieldUpdate('name', v)}
          className="w-64"
        />
        <button
          onClick={onDelete}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Delete
        </button>
      </div>

      <table className="w-full">
      <thead>
        <tr>
        <th className="border p-2 w-1/6">Name</th>
        <th className="border p-2 w-24">Stoichiometry</th>
        <th className="border p-2 w-40">Dependency</th>
        <th className="border p-2 w-24">Parameter</th>
        <th className="border p-2">Equation</th>
      </tr>
      </thead>
        <tbody>
          {particulates.map((p, idx) => (
            <ReactionRow
            key={p.name}
            name={p.name}
            type="particulate"
            stoichiometry={reaction.stoichiometry?.[p.name] || ''}
            dependency={reaction.dependencies?.[p.name]?.type || 'none'}
            parameter={reaction.dependencies?.[p.name]?.parameter || ''}
            reaction={reaction}
            formData={formData}
              isFirstInstance={
                reaction.dependencies?.[p.name]?.type === 'first' &&
                Object.entries(reaction.dependencies || {})
                  .findIndex(([_, dep]) => dep.type === 'first') === 
                Object.entries(reaction.dependencies || {})
                  .findIndex(([name, _]) => name === p.name)
              }
              onStoichiometryChange={v => handleRowUpdate(p.name, 'stoichiometry', v)}
              onDependencyChange={v => handleRowUpdate(p.name, 'dependencies', { type: v, parameter: '' })}
              onParameterChange={v => handleRowUpdate(p.name, 'dependencies', { 
                ...reaction.dependencies?.[p.name],
                parameter: v 
              })}
            />
          ))}
          {solutes.map((s, idx) => (
            <ReactionRow
              key={s.name}
              name={s.name}
              type="solute"
              stoichiometry={reaction.stoichiometry?.[s.name] || ''}
              dependency={reaction.dependencies?.[s.name]?.type || 'none'}
              parameter={reaction.dependencies?.[s.name]?.parameter || ''}
              reaction={reaction}
              formData={formData}
              isFirstInstance={
                reaction.dependencies?.[s.name]?.type === 'first' &&
                Object.entries(reaction.dependencies || {})
                  .findIndex(([_, dep]) => dep.type === 'first') === 
                Object.entries(reaction.dependencies || {})
                  .findIndex(([name, _]) => name === s.name)
              }
              onStoichiometryChange={v => handleRowUpdate(s.name, 'stoichiometry', v)}
              onDependencyChange={v => handleRowUpdate(s.name, 'dependencies', { type: v, parameter: '' })}
              onParameterChange={v => handleRowUpdate(s.name, 'dependencies', { 
                ...reaction.dependencies?.[s.name],
                parameter: v 
              })}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
});

ReactionCard.displayName = 'ReactionCard';

const ReactionsSection = ({ formData, onFormDataChange }) => {
  const handleAddReaction = useCallback(() => {
    const newReaction = {
      name: `Reaction ${formData.section5.reactions.length + 1}`,
      stoichiometry: {},
      dependencies: {}
    };
    
    // Set default stoichiometry values
    formData.section3.forEach(particulate => {
      newReaction.stoichiometry[particulate.name] = '1'; // Default to 1 for particulates
      newReaction.dependencies[particulate.name] = { type: 'none', parameter: '' };
    });
    formData.section4.forEach(solute => {
      newReaction.stoichiometry[solute.name] = '';
      newReaction.dependencies[solute.name] = { type: 'none', parameter: '' };
    });
  
    onFormDataChange({
      ...formData,
      section5: {
        ...formData.section5,
        reactions: [...formData.section5.reactions, newReaction]
      }
    });
  }, [formData, onFormDataChange]);

  const handleUpdateReaction = useCallback((index, updatedReaction) => {
    const newReactions = [...formData.section5.reactions];
    newReactions[index] = updatedReaction;
    onFormDataChange({
      ...formData,
      section5: {
        ...formData.section5,
        reactions: newReactions
      }
    });
  }, [formData, onFormDataChange]);

  const handleDeleteReaction = useCallback((index) => {
    onFormDataChange({
      ...formData,
      section5: {
        ...formData.section5,
        reactions: formData.section5.reactions.filter((_, i) => i !== index)
      }
    });
  }, [formData, onFormDataChange]);

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button
          onClick={handleAddReaction}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add New Reaction
        </button>
      </div>

      {formData.section5.reactions.map((reaction, index) => (
        <ReactionCard
          key={index}
          reaction={reaction}
          particulates={formData.section3}
          solutes={formData.section4}
          formData={formData}
          onUpdate={r => handleUpdateReaction(index, r)}
          onDelete={() => handleDeleteReaction(index)}
        />
      ))}
    </div>
  );
};

const BiofilmSimulationForm = () => {
  const [activeSection, setActiveSection] = useState(1);
  const [openInflowMenus, setOpenInflowMenus] = useState(new Set());
  const [formData, setFormData] = useState({
    section1: {
      title: "Default Biofilm Simulation",
      simulationTime: "10",
      tolerance: "1e-4",
      outputPeriod: "1",
    },
    section2: {
      volume: "1",
      surfaceArea: "1",
      flowrate: "1",
      gridpoints: "100",
      initialThickness: "0.0001",
      boundaryLayerThickness: "0.0001",
      particulateDetachmentCoefficient: "500",
    },
    section3: [
      {
        name: "Sulfide-Oxidizer",
        abbreviation: "SOB",
        volumeFraction: "0.1",
        density: "2.5e5",
        initialConcentration: "1.0e-6",
      },
      {
        name: "Sulfate-Reducer",
        abbreviation: "SRB",
        volumeFraction: "0.1",
        density: "2.5e5",
        initialConcentration: "1.0e-6",
      },
    ],
    section4: [
      {
        name: "Oxygen",
        abbreviation: "O",
        tankInitialConc: "8.6",
        biofilmInitialConc: "8.6",
        substrateDiffusion: "1.51e-4",
        effectiveDiffusion: "6.8e-5",
        inflow: {
          type: "constant",
          constant: { value: "8.6" },
          sinusoidal: { amplitude: "1", period: "24", yOffset: "8.6" },
          periodic: { amplitude: "1", period: "24", duration: "12", startTime: "0" },
          custom: { equation: "" }
        }
      },
      {
        name: "Sulfate",
        abbreviation: "S",
        tankInitialConc: "48.0",
        biofilmInitialConc: "48.0",
        substrateDiffusion: "8e-5",
        effectiveDiffusion: "4e-5",
        inflow: {
          type: "constant",
          constant: { value: "0" },
          sinusoidal: { amplitude: "1", period: "1", yOffset: "0" },
          periodic: { amplitude: "1", period: "2", duration: "1", startTime: "0" },
          custom: { equation: "" }
        }  
      },
    ],
    section5: {
      reactions: [] // Will contain array of reaction objects
    },
  });

  const [userId, setUserId] = useState("");
  const [simulationOutput, setSimulationOutput] = useState("");

  useEffect(() => {
    // Generate a random user ID when the component mounts
    setUserId(Math.random().toString(36).substr(2, 9));
  }, []);

  const handleInputChange = (section, field, e, index = null) => {
  // Get value from event object if it exists, otherwise use the value directly
  const value = e?.target?.value ?? e;
  
  setFormData((prev) => {
    if (index !== null) {
      const newSection = [...prev[section]];
      const fieldParts = field.split('.');
      if (fieldParts.length > 1) {
        const newObj = { ...newSection[index] };
        let current = newObj;
        for (let i = 0; i < fieldParts.length - 1; i++) {
          if (!current[fieldParts[i]]) {
            current[fieldParts[i]] = {};
          }
          current[fieldParts[i]] = { ...current[fieldParts[i]] };
          current = current[fieldParts[i]];
        }
        current[fieldParts[fieldParts.length - 1]] = value;
        newSection[index] = newObj;
      } else {
        newSection[index] = { ...newSection[index], [field]: value };
      }
      return { ...prev, [section]: newSection };
    }
    
    return {
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    };
  });
};

const addObject = (section) => {
  const newObject =
    section === "section4"
      ? {
          name: "",
          abbreviation: "",
          tankInitialConc: "",
          biofilmInitialConc: "",
          substrateDiffusion: "",
          effectiveDiffusion: "",
          inflow: { 
            type: "constant",
            constant: { value: "" },
            sinusoidal: { amplitude: "1", period: "1", yOffset: "0" },
            periodic: { amplitude: "1", period: "2", duration: "1", startTime: "0" },
            custom: { equation: "" }
          }
        }
      : {
          name: "",
          abbreviation: "",
          volumeFraction: "",
          density: "",
          initialConcentration: "",
        };
  setFormData((prev) => ({
    ...prev,
    [section]: [...prev[section], newObject],
  }));
};

  const deleteObject = (section, index) => {
    setFormData((prev) => ({
      ...prev,
      [section]: prev[section].filter((_, i) => i !== index),
    }));
  };

  const updateStoichiometryGrid = useCallback(() => {
    const newStoichiometry = { ...formData.section5.stoichiometry };
    formData.section3.forEach(particulate => {
      newStoichiometry[particulate.name] = newStoichiometry[particulate.name] || {};
      formData.section4.forEach(solute => {
        newStoichiometry[particulate.name][solute.name] = 
          newStoichiometry[particulate.name][solute.name] || '';
      });
    });
  
    setFormData(prev => ({
      ...prev,
      section5: {
        ...prev.section5,
        stoichiometry: newStoichiometry
      }
    }));
  }, [formData.section3, formData.section4]);
  
  const updateKinetics = useCallback(() => {
    const newKinetics = { ...formData.section5.kinetics };
    formData.section3.forEach(particulate => {
      newKinetics[particulate.name] = newKinetics[particulate.name] || {
        mu_max: '',
        dependencies: {}
      };
      formData.section4.forEach(solute => {
        newKinetics[particulate.name].dependencies[solute.name] = 
          newKinetics[particulate.name].dependencies[solute.name] || {
            type: 'zero',
            Km: '',
            Ki: ''
          };
      });
    });
  
    setFormData(prev => ({
      ...prev,
      section5: {
        ...prev.section5,
        kinetics: newKinetics
      }
    }));
  }, [formData.section3, formData.section4]);

  useEffect(() => {
    updateStoichiometryGrid();
    updateKinetics();
}, [formData.section3, formData.section4]);

  // eslint-disable-next-line no-unused-vars
  /*
  const handleGridChange = (particulateName, soluteName, value) => {
    setFormData(prev => ({
      ...prev,
      section5: {
        ...prev.section5,
        stoichiometry: {
          ...prev.section5.stoichiometry,
          [particulateName]: {
            ...(prev.section5.stoichiometry[particulateName] || {}),
            [soluteName]: value
          }
        }
      }
    }));
  };
  */
  
  // eslint-disable-next-line no-unused-vars
  /*
  const handleKineticsChange = (particulateName, field, value, soluteName = null) => {
    setFormData(prev => ({
      ...prev,
      section5: {
        ...prev.section5,
        kinetics: {
          ...prev.section5.kinetics,
          [particulateName]: soluteName
            ? {
                ...(prev.section5.kinetics[particulateName] || {}),
                dependencies: {
                  ...(prev.section5.kinetics[particulateName]?.dependencies || {}),
                  [soluteName]: {
                    ...(prev.section5.kinetics[particulateName]?.dependencies?.[soluteName] || {}),
                    [field]: value
                  }
                }
              }
            : {
                ...(prev.section5.kinetics[particulateName] || {}),
                [field]: value
              }
        }
      }
    }));
  }; 
  */

  const generateInflowString = (solute) => {
    if (!solute.inflow || !solute.inflow.type) {
      return '(t) -> 0.0';
    }
  
    switch (solute.inflow.type) {
      case 'constant':
        return `(t) -> ${solute.inflow.constant.value || '0.0'}`;
        
      case 'sinusoidal': {
        const { amplitude, period, yOffset } = solute.inflow.sinusoidal;
        return `(t) -> ${yOffset} + (${amplitude} * sin(t/${period}))`;
      }
        
      case 'periodic': {
        const { amplitude, period, duration, startTime } = solute.inflow.periodic;
        return `(t) -> ${amplitude} * (1 - heaviside(mod((t - ${startTime}), ${period}) - ${duration}))`;
      }
        
      case 'custom':
        return solute.inflow.custom.equation || '(t) -> 0.0';
        
      default:
        return '(t) -> 0.0';
    }
  };

  const generateSrcXEquation = (particulate, particulates, solutes, reactions) => {
    const baseString = "(S,X,Lf,t,z,p) -> ";
    let equation = "";
    let particulate_index = particulates.findIndex(p => p.name === particulate.name);
    
    // Get all reactions that involve this particulate
    const relevantReactions = reactions.filter(reaction => 
      reaction.stoichiometry?.[particulate.name] !== undefined && 
      reaction.stoichiometry?.[particulate.name] !== '0'
    );
  
    if (relevantReactions.length === 0) {
      return baseString + "0.0";
    }
  
    // Process each reaction
    relevantReactions.forEach((reaction, reactionIndex) => {
      if (reactionIndex > 0) equation += " + ";
      
      // Start with stoichiometry coefficient
      const stoichCoeff = reaction.stoichiometry[particulate.name];
      const isNegative = parseFloat(stoichCoeff) < 0;
      if (isNegative) equation += "-";
      
      // Handle first order dependencies
      const firstDeps = Object.entries(reaction.dependencies || {})
        .filter(([_, dep]) => dep.type === 'first');
      
      const firstParam = firstDeps.find(([_, dep]) => dep.type === 'first')?.[1]?.parameter || '';
      if (firstParam) equation += firstParam;
  
      // Add component terms
      firstDeps.forEach((firstDep, index) => {
        const [depName, _] = firstDep;
        const pIndex = particulates.findIndex(p => p.name === depName);
        const sIndex = solutes.findIndex(s => s.name === depName);
        
        if (index === 0 && firstParam) equation += "*";
        
        if (pIndex !== -1) {
          equation += `X[${pIndex + 1}]`; // Julia is 1-based indexing
        } else if (sIndex !== -1) {
          equation += `S[${sIndex + 1}]`;
        }
        
        if (index < firstDeps.length - 1) equation += "*";
      });
  
      // Add Monod and Inhibition terms
      const otherDeps = Object.entries(reaction.dependencies || {})
        .filter(([_, dep]) => dep.type !== 'none' && dep.type !== 'first');
      
      if (otherDeps.length > 0 && (firstParam || firstDeps.length > 0)) {
        equation += "*";
      }
  
      otherDeps.forEach((dep, index) => {
        const [depName, depInfo] = dep;
        const sIndex = solutes.findIndex(s => s.name === depName);
        
        if (depInfo.type === 'monod') {
          equation += `(S[${sIndex + 1}]/(${depInfo.parameter}+S[${sIndex + 1}]))`;
        } else if (depInfo.type === 'inhibition') {
          equation += `(1/(1+S[${sIndex + 1}]/${depInfo.parameter}))`;
        }
        
        if (index < otherDeps.length - 1) equation += "*";
      });
    });
  
    return baseString + (equation || "0.0");
  };

  const generateSrcSEquation = (solute, particulates, solutes, reactions) => {
    const baseString = "(S,X,Lf,t,z,p) -> ";
    let equation = "";
    let solute_index = solutes.findIndex(s => s.name === solute.name);
    
    // Get all reactions that involve this solute
    const relevantReactions = reactions.filter(reaction => 
      reaction.stoichiometry?.[solute.name] !== undefined && 
      reaction.stoichiometry?.[solute.name] !== '0'
    );
  
    if (relevantReactions.length === 0) {
      return baseString + "0.0";
    }
  
    // Process each reaction
    relevantReactions.forEach((reaction, reactionIndex) => {
      if (reactionIndex > 0) equation += " + ";
      
      // Start with stoichiometry coefficient
      const stoichCoeff = reaction.stoichiometry[solute.name];
      const isNegative = parseFloat(stoichCoeff) < 0;
      const absStorichCoeff = Math.abs(parseFloat(stoichCoeff));
      
      if (isNegative) equation += "-";
      equation += `(1/${absStorichCoeff})*(`;
      
      // Handle first order dependencies
      const firstDeps = Object.entries(reaction.dependencies || {})
        .filter(([_, dep]) => dep.type === 'first');
      
      const firstParam = firstDeps.find(([_, dep]) => dep.type === 'first')?.[1]?.parameter || '';
      if (firstParam) equation += firstParam;
  
      // Add component terms
      firstDeps.forEach((firstDep, index) => {
        const [depName, _] = firstDep;
        const pIndex = particulates.findIndex(p => p.name === depName);
        const sIndex = solutes.findIndex(s => s.name === depName);
        
        if (index === 0 && firstParam) equation += "*";
        
        if (pIndex !== -1) {
          equation += `X[${pIndex + 1}]`; // Julia is 1-based indexing
        } else if (sIndex !== -1) {
          equation += `S[${sIndex + 1}]`;
        }
        
        if (index < firstDeps.length - 1) equation += "*";
      });
  
      // Add Monod and Inhibition terms
      const otherDeps = Object.entries(reaction.dependencies || {})
        .filter(([_, dep]) => dep.type !== 'none' && dep.type !== 'first');
      
      if (otherDeps.length > 0 && (firstParam || firstDeps.length > 0)) {
        equation += "*";
      }
  
      otherDeps.forEach((dep, index) => {
        const [depName, depInfo] = dep;
        const sIndex = solutes.findIndex(s => s.name === depName);
        
        if (depInfo.type === 'monod') {
          equation += `(S[${sIndex + 1}]/(${depInfo.parameter}+S[${sIndex + 1}]))`;
        } else if (depInfo.type === 'inhibition') {
          equation += `(1/(1+S[${sIndex + 1}]/${depInfo.parameter}))`;
        }
        
        if (index < otherDeps.length - 1) equation += "*";
      });
      
      equation += ")";
    });
  
    return baseString + (equation || "0.0");
  };  

  const generateJuliaCode = () => {
    let code = 'p = (\n';
    
    // Simulation Parameters
    code += `    Title = "${formData.section1.title}",\n`;
    code += `    tFinal = ${formData.section1.simulationTime},\n`;
    code += `    tol = ${formData.section1.tolerance},\n`;
    code += `    outPeriod = ${formData.section1.outputPeriod},\n`;
    
    // Geometry Parameters
    code += `    Volume = ${formData.section2.volume},\n`;
    code += `    SurfaceArea = ${formData.section2.surfaceArea},\n`;
    code += `    Flowrate = ${formData.section2.flowrate},\n`;
    code += `    Gridpoints = ${formData.section2.gridpoints},\n`;
    code += `    InitialThickness = ${formData.section2.initialThickness},\n`;
    code += `    BoundaryLayerThickness = ${formData.section2.boundaryLayerThickness},\n`;
    code += `    Kdet = ${formData.section2.particulateDetachmentCoefficient},\n`;
    
    // Particulate Parameters
    code += `    XNames = ${JSON.stringify(formData.section3.map(p => p.name))},\n`;
    code += `    Xto = [${formData.section3.map(p => p.initialConcentration).join(',')}],\n`;
    code += `    Pbo = [${formData.section3.map(p => p.volumeFraction).join(',')}],\n`;
    code += `    rho = [${formData.section3.map(p => p.density).join(',')}],\n`;
    //srcX
    code += `    srcX = [\n`;
    formData.section3.forEach((particulate, index) => {
      code += `        ${generateSrcXEquation(
        particulate,
        formData.section3,
        formData.section4,
        formData.section5.reactions
      )}`;
      if (index < formData.section3.length - 1) {
        code += ',';
      }
      code += '\n';
    });
    code += '    ],\n';
    //mu
    code += `    mu = [\n`;
    formData.section3.forEach((_, index) => {
      code += `        (S,X,Lf,t,z,p) -> 0.0`;
      if (index < formData.section3.length - 1) {
        code += ',';
      }
      code += '\n';
    });
    code += '    ],\n';
    
    // Solute Parameters
    code += `    SNames = ${JSON.stringify(formData.section4.map(s => s.name))},\n`;
    code += `    Sto = [${formData.section4.map(s => s.tankInitialConc).join(',')}],\n`;
    code += `    Sbo = [${formData.section4.map(s => s.biofilmInitialConc).join(',')}],\n`;
    code += `    Dt = [${formData.section4.map(s => s.substrateDiffusion).join(',')}],\n`;
    code += `    Db = [${formData.section4.map(s => s.effectiveDiffusion).join(',')}],\n`;
    //yxs
    code += `    yxs = [${Array(formData.section4.length).fill('0.0').join(' ')} \n\t `.repeat(formData.section3.length - 1);
    code += `${Array(formData.section4.length).fill('0.0').join(' ')}],\n`;
    //srcS
    code += `    srcS = [\n`;
    formData.section4.forEach((solute, index) => {
      code += `        ${generateSrcSEquation(
        solute,
        formData.section3,
        formData.section4,
        formData.section5.reactions
      )}`;
      if (index < formData.section4.length - 1) {
        code += ',';
      }
      code += '\n';
    });
    code += '    ],\n';
    //sin
    code += `    Sin = [\n`;
    formData.section4.forEach((solute, index) => {
      code += `        ${generateInflowString(solute)}`;
      if (index < formData.section4.length - 1) {
        code += ',';
      }
      code += '\n';
    });
    code += '    ],\n'; 
    
    // Close the parameter tuple
    code += ')\n\nprint(p)';
    
    return code;
  };

  const saveToFile = async () => {
    const juliaCode = generateJuliaCode();
    try {
      const response = await fetch("/api/save_file", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, code: juliaCode }),
      });
      if (response.ok) {
        alert("File saved successfully!");
      } else {
        alert("Error saving file");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error saving file");
    }
  };

  const runSimulation = async () => {
    try {
      const response = await fetch("/api/run_simulation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      if (response.ok) {
        const result = await response.text();
        setSimulationOutput(result);
      } else {
        alert("Error running simulation");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error running simulation");
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 1:
        return (
          <div className="space-y-4">
            <Input
              label="Title"
              value={formData.section1.title}
              onChange={(e) =>
                handleInputChange("section1", "title", e)
              }
            />
            <Input
              label="Simulation time (days)"
              value={formData.section1.simulationTime}
              onChange={(e) =>
                handleInputChange("section1", "simulationTime", e)
              }
            />
            <Input
              label="Tolerance"
              value={formData.section1.tolerance}
              onChange={(e) =>
                handleInputChange("section1", "tolerance", e)
              }
            />
            <Input
              label="Output period (days)"
              value={formData.section1.outputPeriod}
              onChange={(e) =>
                handleInputChange("section1", "outputPeriod", e)
              }
            />
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-2">Tank Parameters</h3>
            <Input
              label="Volume (m³)"
              value={formData.section2.volume}
              onChange={(e) => handleInputChange('section2', 'volume', e)}
            />
            <Input
              label="Surface area of biofilm (m²)"
              value={formData.section2.surfaceArea}
              onChange={(e) => handleInputChange('section2', 'surfaceArea', e)}
            />
            <Input
              label="Flowrate through tank (m³/d)"
              value={formData.section2.flowrate}
              onChange={(e) => handleInputChange('section2', 'flowrate', e)}
            />
            <h3 className="text-lg font-semibold mt-6 mb-2">Biofilm Parameters</h3>
            <Input
              label="Number of Gridpoints in Biofilm"
              value={formData.section2.gridpoints}
              onChange={(e) => handleInputChange('section2', 'gridpoints', e)}
            />
            <Input
              label="Biofilm Initial Thickness (m)"
              value={formData.section2.initialThickness}
              onChange={(e) => handleInputChange('section2', 'initialThickness', e)}
            />
            <Input
              label="Boundary Layer Thickness (m)"
              value={formData.section2.boundaryLayerThickness}
              onChange={(e) => handleInputChange('section2', 'boundaryLayerThickness', e)}
            />
            <Input
              label="Particulate Detachment Coefficient"
              value={formData.section2.particulateDetachmentCoefficient}
              onChange={(e) => handleInputChange('section2', 'particulateDetachmentCoefficient', e)}
            />
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            {formData.section3.map((particulate, index) => (
              <div key={index} className="p-4 border rounded mb-4">
                <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                    <Input
                      label="Particulate Name"
                      value={particulate.name}
                      onChange={(e) =>
                        handleInputChange("section3", "name", e, index)
                      }
                    />
                  </div>
                  <div className="w-32">
                    <Input
                      label="Abbreviation"
                      value={particulate.abbreviation}
                      onChange={(e) =>
                        handleInputChange("section3", "abbreviation", e, index)
                      }
                    />
                  </div>
                </div>
                <Input
                  label="Volume Fraction Initial Condition"
                  value={particulate.volumeFraction}
                  onChange={(e) =>
                    handleInputChange(
                      "section3",
                      "volumeFraction",
                      e,
                      index
                    )
                  }
                />
                <Input
                  label="Density (g/m³)"
                  value={particulate.density}
                  onChange={(e) =>
                    handleInputChange(
                      "section3",
                      "density",
                      e,
                      index
                    )
                  }
                />
                <Input
                  label="Initial Concentration (g/m³)"
                  value={particulate.initialConcentration}
                  onChange={(e) =>
                    handleInputChange(
                      "section3",
                      "initialConcentration",
                      e,
                      index
                    )
                  }
                />
                <Button
                  onClick={() => deleteObject("section3", index)}
                  className="bg-red-500 hover:bg-red-600"
                >
                  Delete Particulate
                </Button>
              </div>
            ))}
            <Button onClick={() => addObject("section3")}>
              Add New Particulate
            </Button>
          </div>
        );
        case 4:
          return (
            <SoluteParametersSection 
              formData={formData}
              handleInputChange={handleInputChange}
              openInflowMenus={openInflowMenus}
              setOpenInflowMenus={setOpenInflowMenus}
              addObject={() => addObject("section4")}
              deleteObject={(index) => deleteObject("section4", index)}
            />
          );
        case 5:
          return (
            <ReactionsSection 
              formData={formData}
              onFormDataChange={setFormData}
            />
          );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen">
      {/* Changed from w-1/4 to w-1/5 */}
      <div className="w-1/5 bg-gray-100 p-4 space-y-4">
        <Button
          className={`w-full ${
            activeSection === 1 ? "bg-blue-600" : "bg-blue-500"
          }`}
          onClick={() => setActiveSection(1)}
        >
          Simulation Parameters
        </Button>
        <Button
          className={`w-full ${
            activeSection === 2 ? "bg-blue-600" : "bg-blue-500"
          }`}
          onClick={() => setActiveSection(2)}
        >
          Geometry Parameters
        </Button>
        <Button
          className={`w-full ${
            activeSection === 3 ? "bg-blue-600" : "bg-blue-500"
          }`}
          onClick={() => setActiveSection(3)}
        >
          Particulate Parameters
        </Button>
        <Button
          className={`w-full ${
            activeSection === 4 ? "bg-blue-600" : "bg-blue-500"
          }`}
          onClick={() => setActiveSection(4)}
        >
          Solute Parameters
        </Button>
        <Button
          className={`w-full ${
            activeSection === 5 ? "bg-blue-600" : "bg-blue-500"
          }`}
          onClick={() => setActiveSection(5)}
        >
          Reactions
        </Button>
        <Button className="w-full" onClick={saveToFile}>
          Save to File
        </Button>
        <Button className="w-full" onClick={runSimulation}>
          Run Simulation
        </Button>
      </div>
      {/* Changed from w-3/4 to w-4/5 */}
      <div className="w-4/5 p-4 overflow-auto">
        <h2 className="text-2xl mb-4">
          {activeSection === 1 && "Simulation Parameters"}
          {activeSection === 2 && "Geometry Parameters"}
          {activeSection === 3 && "Particulate Parameters"}
          {activeSection === 4 && "Solute Parameters"}
          {activeSection === 5 && "Reactions"}
        </h2>
        {renderSection()}
        {simulationOutput && (
          <div className="mt-8">
            <h3 className="text-lg font-semibold mb-2">Simulation Output:</h3>
            <pre className="bg-gray-200 p-4 rounded">{simulationOutput}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default BiofilmSimulationForm;
